/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
  CardHeader,
  FormGroup,
  Label,
  Button,
  Badge,
  CardFooter,
  CardTitle,
  CustomInput,
  Modal,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import * as dateFns from "date-fns";
import Pagination from "components/Pagination/Pagination.js";
import DatePicker from "react-datepicker";
import SearchInput from "components/Search/Search.input.component";
import SelfEnrollmentModal from "../SelfEnrollment/SelfEnrollment.Modal.Component";
import { Multiselect } from "multiselect-react-dropdown";
import { SavePdf } from "components/Report/GeneratePdf.js";
import ProgressBar from "components/ProgressBar/ProgressBar.js";
import { editMode } from "helpers/Utils.js";
import StudentEdit2 from "components/Student/Student2.component.edit.jsx";
import "react-datasheet/lib/react-datasheet.css";
import Select from "react-select";
import confirm from "reactstrap-confirm";
import { FinancialContractPdf } from "components/Report/FinancialContractPdf.js";
import { authenticationService } from "services/Authentication.service.js";

class SelfEnrollmentReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getMainState();
  }

  getMainState = () => {
    return {
      selfEnrollments: [],
      StartDateSearch: dateFns.startOfYear(dateFns.subYears(new Date(), 1)),
      EndDateSearch: dateFns.endOfWeek(new Date()),
      selfEnrollmentId: "",
      selfEnrollmentName: "",
      totalResults: 0,
      currentPage: 1,
      itemsPerPage: 10,
      logModalIsOpen: false,
      nameSearch: "",
      StatusTypeList: [],
      selectedStatusTypes: [],
      selectedPlans: [],
      classes: [],
      modalStudentIsOpen: false,
      studentId: "",
      completedStatus: "A",
      showModal: false,
      progress: 0,
      total: 1,
      Application: {},
      currentUser: authenticationService.currentUserValue,
      CourseList: [],
      CampusList: [],
      financialPlan: "A",
      period: "A",
      levelGuid: "",
      campusGuid: "",
      passed: "A",
      balance: "A",
      reason: "A",
    };
  };

  toggleLogModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        { logModalIsOpen: !this.state.logModalIsOpen },
        resolve(true)
      );
    });
  };

  onCloseModal = () => {
    this.resetModal();
  };

  resetModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        { selfEnrollmentId: "", selfEnrollmentName: "" },
        resolve(true)
      );
    });
  };

  setSelfEnrollmentIdAndToggleLog = (id, name) => {
    return new Promise((resolve, reject) => {
      this.setState({ selfEnrollmentId: id, name: name }, () =>
        this.toggleLogModal()
      );
    });
  };

  handleSearchInput = (name, e) => {
    const value = e.target.value;

    this.setState({ [name]: value, currentPage: 1 }, () => {
      this._refreshSelfEnrollments();
    });
  };

  renderStatusButton = (selfEnrollment) => {
    let color = "";

    switch (selfEnrollment.statusOrder) {
      case 0:
        color = "#f9a02b";
        break;
      case 1:
        color = "#10b8f9";
        break;
      case 2:
        color = "#7ac947";
        break;
      case 3:
        color = "#dc3545";
        break;
      case 4:
        color = "#5b5b5b";
        break;
      case 5:
        color = "#9d00ff";
        break;

      default:
        break;
    }

    return (
      <React.Fragment>
        <Badge style={{ fontSize: "large", backgroundColor: color }}>
          {selfEnrollment.statusTypeName}
        </Badge>
      </React.Fragment>
    );
  };

  renderIssuesButton = (selfEnrollment) => {
    let hasBalanceBadge = selfEnrollment.hasBalance && (
      <Badge
        className="badgeTransaction"
        style={{ fontSize: "small", backgroundColor: "#dc3545" }}
      >
        Balance
      </Badge>
    );

    let hasReasonBadge = (selfEnrollment.levelReason?.length > 0 ||
      selfEnrollment.otherReason?.length > 0) && (
      <Badge
        className="badgeTransaction"
        style={{ fontSize: "small", backgroundColor: "#fbc658" }}
      >
        Reason
      </Badge>
    );

    return (
      <React.Fragment>
        {hasBalanceBadge}
        {hasReasonBadge}
      </React.Fragment>
    );
  };

  toggleShowCompletedSelfEnrollments = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          showCompletedSelfEnrollments:
            !this.state.showCompletedSelfEnrollments,
        },
        () => {
          this._refreshSelfEnrollments();
        }
      );
    });
  };

  handleCompletedStatusChange = (selectedOption) => {
    let mainState = this.state;

    mainState.completedStatus = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshSelfEnrollments();
    });
  };

  handlePassedChange = (selectedOption) => {
    let mainState = this.state;

    mainState.passed = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshSelfEnrollments();
    });
  };

  handleBalanceChange = (selectedOption) => {
    let mainState = this.state;

    mainState.balance = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshSelfEnrollments();
    });
  };

  handleReasonChange = (selectedOption) => {
    let mainState = this.state;

    mainState.reason = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshSelfEnrollments();
    });
  };

  handleFinancialPlanChange = (selectedOption) => {
    let mainState = this.state;

    mainState.financialPlan = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshSelfEnrollments();
    });
  };

  handlePeriodChange = (selectedOption) => {
    let mainState = this.state;

    mainState.period = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshSelfEnrollments();
    });
  };

  handleLevelChange = (selectedOption) => {
    let mainState = this.state;

    mainState.levelGuid = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshSelfEnrollments();
    });
  };

  handleCampusChange = (selectedOption) => {
    let mainState = this.state;

    mainState.campusGuid = selectedOption.value;
    mainState.currentPage = 1;

    this.setState({ mainState: mainState }, () => {
      this._refreshSelfEnrollments();
    });
  };

  async loadImage(id) {
    const response = await axios.get("documents/getFileByDate?id=" + id, {
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "image/png",
      })
    );

    return url;
  }

  generatePdf = async (data, index) => {
    let selfEnrollmentObj = {};

    await axios
      .get("selfenroll/GetSelfEnrollLogs?id=" + data.id)
      .then((response) => {
        selfEnrollmentObj.Id = response.data.data.id;
        selfEnrollmentObj.Plan = response.data.data.plan;
        selfEnrollmentObj.FinancialPlan = response.data.data.financialPlan;
        selfEnrollmentObj.Period = response.data.data.period;
        selfEnrollmentObj.LevelId = response.data.data.levelId;
        selfEnrollmentObj.CampusId = response.data.data.campusId;
        selfEnrollmentObj.Email = response.data.data.email;
        selfEnrollmentObj.PhoneNumber = response.data.data.phoneNumber;
        selfEnrollmentObj.Street = response.data.data.street;
        selfEnrollmentObj.Street2 = response.data.data.street2;
        selfEnrollmentObj.City = response.data.data.city;
        selfEnrollmentObj.State = response.data.data.state;
        selfEnrollmentObj.Zip = response.data.data.zip;
        selfEnrollmentObj.OtherReason = response.data.data.otherReason;
        selfEnrollmentObj.LevelReason = response.data.data.levelReason;
        selfEnrollmentObj.Semester = response.data.data.semester;
        selfEnrollmentObj.SemesterStartDate =
          response.data.data.semesterStartDate;
        selfEnrollmentObj.StudentId = response.data.data.student.id;
        selfEnrollmentObj.StudentI20 = response.data.data.student.i20;
        selfEnrollmentObj.StudentName = response.data.data.student.name;
        selfEnrollmentObj.StudentVisaTypeName =
          response.data.data.student.visaType.name;
        selfEnrollmentObj.StudentBirthdate =
          response.data.data.student.birthDate;
        selfEnrollmentObj.DocumentSignatureId =
          response.data.data.documentSignature.id;
        selfEnrollmentObj.DocumentSignatureFileName =
          response.data.data.documentSignature.fileName;
        selfEnrollmentObj.DocumentInitialsId =
          response.data.data.documentInitials.id;
        selfEnrollmentObj.DocumentInitialsFileName =
          response.data.data.documentInitials.fileName;
        selfEnrollmentObj.NumberOfSemesters =
          response.data.data.numberOfSemesters;
      })
      .then(async () => {
        let signPicture = null;
        let initialsPicture = null;

        signPicture = await this.loadImage(
          selfEnrollmentObj.DocumentSignatureId
        );

        initialsPicture = await this.loadImage(
          selfEnrollmentObj.DocumentInitialsId
        );

        let course = this.props.tables.courses.find(
          (x) => x.id == selfEnrollmentObj.LevelId
        );

        let balanceMonths =
          selfEnrollmentObj.FinancialPlan === "1"
            ? 1
            : selfEnrollmentObj.FinancialPlan === "2"
            ? 3
            : selfEnrollmentObj.FinancialPlan === "3"
            ? 1
            : selfEnrollmentObj.FinancialPlan === "4"
            ? 2
            : selfEnrollmentObj.FinancialPlan === "5"
            ? 3
            : 0;

        let semesterStartDate = dateFns.parseISO(
          selfEnrollmentObj.SemesterStartDate
        );

        let firstPaymentDate =
          selfEnrollmentObj.FinancialPlan == "1" ||
          selfEnrollmentObj.FinancialPlan == "2"
            ? semesterStartDate
            : new Date(2024, 12, 10);

        let secondPaymentDate = null;
        let thirdPaymentDate = null;

        let tuitionCost = course.newTuitionCost;

        switch (selfEnrollmentObj.NumberOfSemesters) {
          case 0:
            break;
          case 1:
            tuitionCost -= course.discount2nd;
            break;
          case 2:
            tuitionCost -= course.discount3rd;
            break;
          default:
            if (selfEnrollmentObj.NumberOfSemesters >= 3) {
              tuitionCost -= course.discount4th;
            }
            break;
        }

        let cardFee = tuitionCost * 0.04;

        let tuition1st = tuitionCost;
        let cardFee1st = cardFee;
        let tuition2nd = null;
        let cardFee2nd = null;
        let tuition3rd = null;
        let cardFee3rd = null;

        if (balanceMonths === 2) {
          tuition1st = tuitionCost / 2;
          cardFee1st = (tuitionCost / 2) * 0.04;

          tuition2nd = tuitionCost / 2;
          cardFee2nd = (tuitionCost / 2) * 0.04;

          secondPaymentDate = dateFns.addMonths(semesterStartDate, 1);
        } else if (balanceMonths === 3) {
          tuition1st = tuitionCost / 2;
          cardFee1st = (tuitionCost / 2) * 0.04;

          tuition2nd = tuitionCost / 4;
          cardFee2nd = (tuitionCost / 4) * 0.04;

          tuition3rd = tuitionCost / 4;
          cardFee3rd = (tuitionCost / 4) * 0.04;

          secondPaymentDate = dateFns.addMonths(semesterStartDate, 1);
          thirdPaymentDate = dateFns.addMonths(semesterStartDate, 2);
        }

        let options = {
          isSelfEnrollment: true,
          canSchoolSendMessage: true,

          tuitionCost: tuitionCost,
          totalCost: tuitionCost + cardFee,
          balanceCost: tuitionCost + cardFee,
          cardFee: cardFee,

          firstPaymentCost: tuition1st,
          firstPaymentFee: cardFee1st,

          secondPaymentCost: tuition2nd,
          secondPaymentFee: cardFee2nd,

          thirdPaymentCost: tuition3rd,
          thirdPaymentFee: cardFee3rd,

          balanceMonths: balanceMonths,
          firstPaymentDate: firstPaymentDate,
          secondPaymentDate: secondPaymentDate,
          thirdPaymentDate: thirdPaymentDate,

          date: new Date(),
          currentSemesterName: selfEnrollmentObj.Semester,
          currentUser: this.state.currentUser,
        };

        let studentObj = {
          id: selfEnrollmentObj.StudentId,
          name: selfEnrollmentObj.StudentName,
          street: selfEnrollmentObj.Street + " " + selfEnrollmentObj.Street2,
          city: selfEnrollmentObj.City,
          state: selfEnrollmentObj.State,
          zip: selfEnrollmentObj.Zip,
          email: selfEnrollmentObj.Email,
          cellPhone: selfEnrollmentObj.PhoneNumber,
          signatureImg: signPicture,
          initialsImg: initialsPicture,
          signatureToken: "",
          i20: selfEnrollmentObj.StudentI20,
          visaType: selfEnrollmentObj.StudentVisaTypeName,
          dateOfBirth: selfEnrollmentObj.StudentBirthdate,
        };

        FinancialContractPdf(studentObj, options);
      })
      .then(() => {
        this.setState((prevState) => ({
          progress: ((index + 1) / prevState.total) * 100,
        }));
      });
  };

  handleGeneratePdfs = async () => {
    let result = await confirm({
      title: <>Warning</>,
      message:
        "Would you like to begin generating contracts now? Once started, the process cannot be stopped.",
    });

    if (result) {
      this.setState({ showModal: true, progress: 0 });

      let records = 0;

      let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
      let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");

      let statusTypeList = [];
      this.state.selectedStatusTypes.map((statusType, index) =>
        statusTypeList.push({
          Id: statusType.id,
        })
      );

      let planList = [];
      this.state.selectedPlans.map((plan, index) => planList.push(plan.id));

      let financialPlan = this.state.financialPlan;
      let period = this.state.period;
      let levelGuid = this.state.levelGuid;
      let campusGuid = this.state.campusGuid;
      let passed = this.state.passed;
      let balance = this.state.balance;
      let reason = this.state.reason;
      let completedStatus = this.state.completedStatus;

      if (financialPlan === "A") financialPlan = "";
      if (period === "A") period = "";
      if (completedStatus === "A") completedStatus = "";
      if (levelGuid === "all") levelGuid = "";
      if (campusGuid === "all") campusGuid = "";

      passed = passed == "A" ? "" : passed;
      balance = balance == "A" ? "" : balance == "1" ? true : false;
      reason = reason == "A" ? "" : reason == "1" ? true : false;

      let getAllParam = {
        startDate: startDate,
        endDate: endDate,
        nameSearch: this.state.nameSearch,
        statusTypeList: statusTypeList,
        planList: planList,
        completedStatus: completedStatus,
        financialPlan: financialPlan,
        period: period,
        levelGuid: levelGuid,
        campusGuid: campusGuid,
        passed: passed,
        balance: balance,
        reason: reason,
      };

      await axios
        .post("selfEnroll/GetSelfEnrollmentForContract?", getAllParam)
        .then((response) => {
          records = response.data.data;
          this.setState({ total: records.length });
        });

      for (let i = 0; i < records.length; i++) {
        await this.generatePdf(records[i], i);
      }

      setTimeout(() => {
        this.setState({ showModal: false });
      }, 1000);
    }
  };

  render() {
    let selfEnrollments = this.state.selfEnrollments.map((se, index) => {
      return (
        <tr
          key={index}
          style={{
            backgroundColor: se.passed == "1" ? "#befcbe" : "",
          }}
        >
          <td>
            <a
              onClick={this.setStudentIdAndOpenModal.bind(this, se.studentId)}
              href="#"
            >
              {se.i20}
            </a>
          </td>

          <td>{se.name}</td>
          <td>{se.plan}</td>
          <td>{se.financialPlan}</td>
          <td>{se.period}</td>
          <td>{se.semester}</td>
          <td>{se.level}</td>
          <td>{se.campus}</td>
          <td>{se.passed == "1" ? "Yes" : se.passed == "0" ? "No" : ""}</td>
          <td>{this.renderStatusButton(se)}</td>
          <td>{this.renderIssuesButton(se)}</td>

          <td>{dateFns.format(se.createdDate, "MM/dd/yyyy ")}</td>
          <td>
            {" "}
            <Button
              color="warning"
              size="sm"
              className="mr-2"
              onClick={this.setSelfEnrollmentIdAndToggleLog.bind(
                this,
                se.id,
                se.name
              )}
            >
              Log
            </Button>
          </td>
        </tr>
      );
    });

    let classGroups = this.state.classes.map((item, index) => {
      return (
        <tr key={index}>
          <td> {item.plan} </td>
          <td> {item.semesterName} </td>
          <td> {item.level} </td>
          <td> {item.campusName} </td>
          <td> {item.period} </td>

          <td className="text-right">
            <strong>{item.total}</strong>
          </td>
        </tr>
      );
    });

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row form>
                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>Start Date</Label>

                      <DatePicker
                        selected={this.state.StartDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "StartDateSearch"
                        )}
                        name="StartDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <FormGroup style={{ marginLeft: "5px" }}>
                      <Label>End Date</Label>
                      <DatePicker
                        selected={this.state.EndDateSearch}
                        onChange={this.handleSearchDateInput.bind(
                          this,
                          "EndDateSearch"
                        )}
                        name="EndDateSearch"
                        dateFormat="MM/dd/yyyy"
                      />
                    </FormGroup>

                    <SearchInput
                      searchInput={this.state.nameSearch}
                      placeholder="Search by name"
                      handleSearchInput={this.handleSearchInput.bind(
                        this,
                        "nameSearch"
                      )}
                    />

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Completed status</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.completedStatus &&
                            this.completedStatusOptions.find(
                              (x) => x.value === this.state.completedStatus
                            )
                          }
                          onChange={this.handleCompletedStatusChange}
                          options={this.completedStatusOptions}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Financial Plan</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.financialPlan &&
                            this.financialPlanOptions.find(
                              (x) => x.value === this.state.financialPlan
                            )
                          }
                          onChange={this.handleFinancialPlanChange}
                          options={this.financialPlanOptions}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Period</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.period &&
                            this.periodOptions.find(
                              (x) => x.value === this.state.period
                            )
                          }
                          onChange={this.handlePeriodChange}
                          options={this.periodOptions}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Level</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.levelGuid &&
                            this.state.CourseList.find(
                              (x) => x.value === this.state.levelGuid
                            )
                          }
                          onChange={this.handleLevelChange}
                          options={this.state.CourseList}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Label for="studentClass">Campus</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.campusGuid &&
                            this.state.CampusList.find(
                              (x) => x.value === this.state.campusGuid
                            )
                          }
                          onChange={this.handleCampusChange}
                          options={this.state.CampusList}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={1}>
                      <FormGroup>
                        <Label for="studentClass">Passed</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.passed &&
                            this.passedOptions.find(
                              (x) => x.value === this.state.passed
                            )
                          }
                          onChange={this.handlePassedChange}
                          options={this.passedOptions}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={1}>
                      <FormGroup>
                        <Label for="studentClass">Balance</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.balance &&
                            this.balanceOptions.find(
                              (x) => x.value === this.state.balance
                            )
                          }
                          onChange={this.handleBalanceChange}
                          options={this.balanceOptions}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={1}>
                      <FormGroup>
                        <Label for="studentClass">Reason</Label>
                        <Select
                          className={"form-group"}
                          value={
                            this.state.reason &&
                            this.reasonOptions.find(
                              (x) => x.value === this.state.reason
                            )
                          }
                          onChange={this.handleReasonChange}
                          options={this.reasonOptions}
                        />
                      </FormGroup>
                    </Col>

                    <FormGroup>
                      <Label>&nbsp;</Label>

                      <Button
                        style={{ margin: "0" }}
                        color="primary"
                        onClick={this.execute}
                      >
                        Enroll All
                      </Button>
                    </FormGroup>

                    <FormGroup>
                      {" "}
                      <Label>&nbsp;</Label>
                      <Button
                        onClick={this.handleGeneratePdfs}
                        style={{ margin: "0", marginLeft: "1rem" }}
                        color="primary"
                      >
                        Create Contracts{" "}
                      </Button>
                    </FormGroup>
                  </Row>

                  <Row form>
                    <Col md={6}>
                      <Label>Status</Label>
                      <Multiselect
                        options={this.state.StatusTypeList}
                        onSelect={this.onSelect.bind(this)}
                        onRemove={this.onRemove.bind(this)}
                        displayValue="name"
                      />
                    </Col>

                    <Col md={6}>
                      <Label>Plan</Label>
                      <Multiselect
                        options={this.planOptions}
                        onSelect={this.onSelectPlan.bind(this)}
                        onRemove={this.onRemovePlan.bind(this)}
                        displayValue="name"
                      />
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>I-20</th>
                        <th>Name</th>
                        <th>Plan</th>
                        <th>Financial</th>
                        <th>Period</th>
                        <th>Semester</th>
                        <th>Level</th>
                        <th>Campus</th>
                        <th>Passed</th>
                        <th>Status</th>
                        <th>Issues</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody>{selfEnrollments}</tbody>
                  </Table>

                  <Pagination
                    activePage={this.state.currentPage}
                    totalItemsCount={this.state.totalResults}
                    onChange={this._refreshSelfEnrollments.bind(this)}
                    csv={this.DownloadCsv.bind(this)}
                    buttonName="Export to CSV"
                  />

                  <SelfEnrollmentModal
                    isOpen={this.state.logModalIsOpen}
                    toggle={this.toggleLogModal.bind(this)}
                    id={this.state.selfEnrollmentId}
                    callback={this._refreshSelfEnrollments.bind(
                      this,
                      this.state.currentPage
                    )}
                    name={this.state.name}
                    tables={this.props.tables}
                  />
                </CardBody>
              </Card>

              <Col md="6" style={{ paddingLeft: "0px" }}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h5">Classes</CardTitle>
                  </CardHeader>

                  <CardBody>
                    <Table id="tableClassGroups">
                      <thead className="text-primary">
                        <tr>
                          <th>Plan</th>
                          <th>Semester</th>
                          <th>Level</th>
                          <th>Campus</th>
                          <th>Period</th>
                          <th className="text-right">Total</th>
                        </tr>
                      </thead>
                      <tbody>{classGroups}</tbody>
                    </Table>
                  </CardBody>

                  {this.state.classes.length > 0 && (
                    <CardFooter>
                      <hr />
                      <Row>
                        <h6
                          style={{
                            marginLeft: "25px",
                            marginRight: "auto",
                            marginTop: "15px",
                            textTransform: "inherit",
                          }}
                        >
                          <strong className="text-secondary">
                            {this.state.classes.length}
                          </strong>{" "}
                          {this.state.classes.length > 1 ? "records" : "record"}
                        </h6>

                        <Button
                          color="success"
                          size="sm"
                          style={{
                            textTransform: "inherit",
                            marginRight: "10px",
                            marginBottom: "10px",
                            marginLeft: "1px",
                            marginTop: "10px",
                          }}
                          onClick={SavePdf.bind(
                            this,
                            "Classes",
                            this.state.classes,
                            [
                              {
                                plan: "Plan",
                                semesterName: "Semester",
                                level: "Level",
                                campusName: "Campus",
                                period: "Period",
                                total: "Total",
                              },
                            ]
                          )}
                        >
                          Export to PDF
                        </Button>

                        <Button
                          color="success"
                          size="sm"
                          style={{
                            textTransform: "inherit",
                            marginRight: "25px",
                            marginBottom: "10px",
                            marginLeft: "1px",
                            marginTop: "10px",
                          }}
                          onClick={this.downloadCSVClasses.bind(this)}
                        >
                          Export to CSV
                        </Button>
                      </Row>
                    </CardFooter>
                  )}
                </Card>
              </Col>
            </Col>
          </Row>

          <StudentEdit2
            isOpen={this.state.modalStudentIsOpen}
            toggleStudentModal={this.toggleStudentModal.bind(this)}
            editMode={editMode.EDIT}
            id={this.state.studentId}
            tables={this.props.tables}
          />

          <Modal
            isOpen={this.state.showModal}
            centered={true}
            autoFocus={true}
            backdrop="static"
          >
            <ModalBody>
              <Row form>
                <Col md={12}>
                  <Label>Creating contracts. Please wait.</Label>
                  <ProgressBar progress={this.state.progress} />
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </>
    );
  }

  componentDidMount() {
    this._refreshSelfEnrollments().then(() => {
      this.refreshStatusTypeList().then(() => {
        this.refreshCourses().then(() => {
          this.refreshCampuses().then(() => {
            this._refreshClasses();
          });
        });
      });
    });
  }

  onSelect(selectedList, selectedItem) {
    let mainState = this.state;

    mainState.currentPage = 1;
    mainState.selectedStatusTypes.push(selectedItem);

    this.setState(mainState, () => this._refreshSelfEnrollments());
  }

  onSelectPlan(selectedList, selectedItem) {
    let mainState = this.state;

    mainState.currentPage = 1;
    mainState.selectedPlans.push(selectedItem);

    this.setState(mainState, () => this._refreshSelfEnrollments());
  }

  onRemove(selectedList, removedItem) {
    let mainState = this.state;
    let newSelectedStatusTypes = mainState.selectedStatusTypes.filter(
      (x) => x.id !== removedItem.id
    );
    mainState.selectedStatusTypes = newSelectedStatusTypes;
    mainState.currentPage = 1;

    this.setState(mainState, () => this._refreshSelfEnrollments());
  }

  onRemovePlan(selectedList, removedItem) {
    let mainState = this.state;

    let newSelectedPlans = mainState.selectedPlans.filter(
      (x) => x.id !== removedItem.id
    );
    mainState.selectedPlans = newSelectedPlans;
    mainState.currentPage = 1;

    this.setState(mainState, () => this._refreshSelfEnrollments());
  }

  refreshStatusTypeList = () => {
    return new Promise((resolve, reject) => {
      axios.get("selfEnrollstatustypes").then((response) => {
        let mainState = this.state;

        mainState.StatusTypeList = [];

        response.data.data.map((statusType, index) =>
          mainState.StatusTypeList.push({
            id: statusType.id,
            name: statusType.name,
          })
        );

        this.setState(mainState, resolve(true));
      });
    });
  };

  handleSearchDateInput = (name, date) => {
    if (date === null) date = new Date();

    let mainState = this.state;

    mainState[name] = date;
    mainState.currentPage = 1;

    this.setState(mainState, () => {
      this._refreshSelfEnrollments();
    });
  };

  _refreshSelfEnrollments(pageNumber) {
    let page = pageNumber || this.state.currentPage || 1;
    let itemsPerPage = this.state.itemsPerPage;
    let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
    let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");

    let statusTypeList = [];
    this.state.selectedStatusTypes.map((statusType, index) =>
      statusTypeList.push({
        Id: statusType.id,
      })
    );

    let planList = [];
    this.state.selectedPlans.map((plan, index) => planList.push(plan.id));

    let financialPlan = this.state.financialPlan;
    let period = this.state.period;
    let levelGuid = this.state.levelGuid;
    let campusGuid = this.state.campusGuid;
    let passed = this.state.passed;
    let balance = this.state.balance;
    let reason = this.state.reason;
    let completedStatus = this.state.completedStatus;

    if (financialPlan === "A") financialPlan = "";
    if (period === "A") period = "";
    if (completedStatus === "A") completedStatus = "";
    if (levelGuid === "all") levelGuid = "";
    if (campusGuid === "all") campusGuid = "";

    passed = passed == "A" ? "" : passed;
    balance = balance == "A" ? "" : balance == "1" ? true : false;
    reason = reason == "A" ? "" : reason == "1" ? true : false;

    let getAllParam = {
      page: page,
      pageSize: itemsPerPage,
      startDate: startDate,
      endDate: endDate,
      nameSearch: this.state.nameSearch,
      statusTypeList: statusTypeList,
      planList: planList,
      completedStatus: completedStatus,
      financialPlan: financialPlan,
      period: period,
      levelGuid: levelGuid,
      campusGuid: campusGuid,
      passed: passed,
      balance: balance,
      reason: reason,
    };

    return new Promise((resolve, reject) => {
      axios.post("selfEnroll/getall?", getAllParam).then((response) => {
        let mainState = this.state;

        mainState.selfEnrollments = [];
        response.data.data.items.map((se, index) =>
          mainState.selfEnrollments.push({
            i20: se.student.i20,
            id: se.id,
            name: se.student.name,
            studentId: se.student.id,
            createdDate: dateFns.parseISO(se.createdDate),
            plan: se.plan,
            financialPlan: this.getFinancialPlan(se.financialPlan),
            period: se.period,
            hasBalance: se.hasBalance,
            semester: se.semester
              .replace("Winter", "W.")
              .replace("Summer", "S.")
              .replace("Fall", "F."),
            level: se.level && se.level.replace("TOEFL Program", "TOEFL"),
            campus: se.campus
              .replace("Salt Lake in Person", "SLC")
              .replace("Orem In Person", "OREM")
              .replace("Online Combined Campuses", "Online"),
            levelReason: se.levelReason,
            otherReason: se.otherReason,
            email: se.email,
            passed:
              se.student.passed == true
                ? "1"
                : se.student.passed == false
                ? "0"
                : "",

            statusTypeName:
              se.selfEnrollLogList[0].status &&
              se.selfEnrollLogList[0].status.name,

            statusOrder:
              se.selfEnrollLogList[0].status &&
              se.selfEnrollLogList[0].status.order,
          })
        );

        mainState.currentPage = pageNumber;
        mainState.totalResults = response.data.data.totalCount;

        this.setState(mainState, resolve(true));
      });
    });
  }

  _refreshClasses() {
    return new Promise((resolve, reject) => {
      axios.get("dashboard/GetSelfEnrollClasses?").then((response) => {
        let classes = [];
        response.data.data.classGroups.map((cg, index) =>
          classes.push({
            plan: cg.name,
            semesterName: cg.semesterName,
            level: cg.level,
            campusName: cg.campusName,
            period: cg.period,
            total: cg.total,
          })
        );

        this.setState({ classes: classes }, resolve(true));
      });
    });
  }

  getFinancialPlan(financialPlan) {
    switch (financialPlan) {
      case "1":
        return "1";

      case "2":
        return "3";

      case "3":
        return "1-Disc";

      case "4":
        return "2-Disc";

      case "5":
        return "3-Disc";

      default:
        return "";
    }
  }

  downloadCSVClasses = () => {
    let classesCsv = [];

    this.state.classes.forEach((item) => {
      classesCsv.push({
        Plan: item.plan,
        Semester: item.semesterName,
        Level: item.level,
        Campus: item.campusName,
        Period: item.period,
        total: item.total,
      });
    });

    this.downloadCSVFromJson("Classes.csv", classesCsv);
  };

  downloadCSVFromJson = (filename, arrayOfJson) => {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(arrayOfJson[0]);
    let csv = arrayOfJson.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(";")
    );
    csv.unshift(header.join(";"));
    csv = csv.join("\r\n");

    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  toggleStudentModal = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        { modalStudentIsOpen: !this.state.modalStudentIsOpen },
        resolve(true)
      );
    });
  };

  setStudentIdAndOpenModal = (id) => {
    this.setState({ studentId: id }, () => {
      this.toggleStudentModal();
    });
  };

  planOptions = [
    { id: "S", name: "Keep studying" },
    { id: "B", name: "Take a break" },
    { id: "C", name: "Transfer to college" },
    { id: "O", name: "Other" },
  ];

  DownloadCsv() {
    let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
    let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");

    let statusTypeList = [];
    this.state.selectedStatusTypes.map((statusType, index) =>
      statusTypeList.push({
        Id: statusType.id,
      })
    );

    let planList = [];
    this.state.selectedPlans.map((plan, index) => planList.push(plan.id));

    let financialPlan = this.state.financialPlan;
    let period = this.state.period;
    let levelGuid = this.state.levelGuid;
    let campusGuid = this.state.campusGuid;
    let passed = this.state.passed;
    let balance = this.state.balance;
    let reason = this.state.reason;
    let completedStatus = this.state.completedStatus;

    if (financialPlan === "A") financialPlan = "";
    if (period === "A") period = "";
    if (completedStatus === "A") completedStatus = "";
    if (levelGuid === "all") levelGuid = "";
    if (campusGuid === "all") campusGuid = "";

    passed = passed == "A" ? "" : passed;
    balance = balance == "A" ? "" : balance == "1" ? true : false;
    reason = reason == "A" ? "" : reason == "1" ? true : false;

    let getAllParam = {
      page: 1,
      pageSize: 999999999,
      startDate: startDate,
      endDate: endDate,
      nameSearch: this.state.nameSearch,
      statusTypeList: statusTypeList,
      planList: planList,
      completedStatus: completedStatus,
      financialPlan: financialPlan,
      period: period,
      levelGuid: levelGuid,
      campusGuid: campusGuid,
      passed: passed,
      balance: balance,
      reason: reason,
    };

    axios.post("selfEnroll/getall?", getAllParam).then((response) => {
      let selfEnrollments = [];

      response.data.data.items.map((se, index) =>
        selfEnrollments.push({
          i20: se.student.i20,
          id: se.id,
          name: se.student.name,
          studentId: se.student.id,
          createdDate: dateFns.parseISO(se.createdDate),
          plan: se.plan,
          financialPlan: this.getFinancialPlan(se.financialPlan),
          period: se.period,
          semester: se.semester,
          level: se.level,
          campus: se.campus,
          levelReason: se.levelReason,
          email: se.email,
          sevisName: se.student.sevisName,

          statusTypeName:
            se.selfEnrollLogList[0].status &&
            se.selfEnrollLogList[0].status.name,

          statusOrder:
            se.selfEnrollLogList[0].status &&
            se.selfEnrollLogList[0].status.order,
        })
      );

      let selfEnrollmentsCsv = [];

      selfEnrollments.forEach((item) => {
        selfEnrollmentsCsv.push({
          I20: item.i20,
          Name: item.name,
          Email: item.email,
          Plan: item.plan,
          Financial: item.financialPlan,
          Period: item.period,
          Semester: item.semester,
          Level: item.level,
          Campus: item.campus,
          Status: item.statusTypeName,
          Date: dateFns.format(item.createdDate, "MM/dd/yyyy hh:mm:ss aa"),
          SevisOrigin: item.sevisName,
        });
      });

      this.downloadCSVFromJson("SelfEnroll.csv", selfEnrollmentsCsv);
    });
  }

  execute = async () => {
    let result = await confirm({
      title: <>Warning</>,
      message:
        "This operation will save the 'Keep studying' and 'Break' self-enrollments, which are approved. Do you want to proceed?",
    });

    if (result) {
      let startDate = dateFns.format(this.state.StartDateSearch, "yyyy-MM-dd");
      let endDate = dateFns.format(this.state.EndDateSearch, "yyyy-MM-dd");

      let statusTypeList = [];
      this.state.selectedStatusTypes.map((statusType, index) =>
        statusTypeList.push({
          Id: statusType.id,
        })
      );

      let planList = [];
      this.state.selectedPlans.map((plan, index) => planList.push(plan.id));

      let financialPlan = this.state.financialPlan;
      let period = this.state.period;
      let levelGuid = this.state.levelGuid;
      let campusGuid = this.state.campusGuid;
      let passed = this.state.passed;
      let balance = this.state.balance;
      let reason = this.state.reason;
      let completedStatus = this.state.completedStatus;

      if (financialPlan === "A") financialPlan = "";
      if (period === "A") period = "";
      if (completedStatus === "A") completedStatus = "";
      if (levelGuid === "all") levelGuid = "";
      if (campusGuid === "all") campusGuid = "";

      passed = passed == "A" ? "" : passed;
      balance = balance == "A" ? "" : balance == "1" ? true : false;
      reason = reason == "A" ? "" : reason == "1" ? true : false;

      let getAllParam = {
        startDate: startDate,
        endDate: endDate,
        nameSearch: this.state.nameSearch,
        statusTypeList: statusTypeList,
        planList: planList,
        completedStatus: completedStatus,
        financialPlan: financialPlan,
        period: period,
        levelGuid: levelGuid,
        campusGuid: campusGuid,
        passed: passed,
        balance: balance,
        reason: reason,
      };

      return new Promise((resolve, reject) => {
        axios
          .post("selfEnroll/CompleteSelfEnrollment?", getAllParam)
          .then((response) => {
            let mainState = this.state;

            this.setState(mainState, resolve(true));
          });
      });
    }
  };

  refreshCourses = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      modalState.CourseList.push({
        value: "all",
        label: "All levels",
      });

      modalState.levelGuid = "all";

      this.props.tables.courses.map((course, index) =>
        modalState.CourseList.push({
          value: course.id,
          label: course.name,
        })
      );

      this.setState(modalState, resolve(true));
    });
  };

  refreshCampuses = () => {
    return new Promise((resolve, reject) => {
      let modalState = this.state;

      modalState.CampusList.push({
        value: "all",
        label: "All campuses",
      });

      modalState.campusGuid = "all";

      this.props.tables.campuses
        .filter(
          (x) => x.name == "Salt Lake in Person" || x.name == "Orem In Person"
        )
        .map((campus, index) =>
          modalState.CampusList.push({
            value: campus.id,
            label: campus.name
              .replace("Salt Lake in Person", "Salt Lake City")
              .replace("Orem In Person", "Orem"),
          })
        );

      this.setState(modalState, resolve(true));
    });
  };

  completedStatusOptions = [
    { value: "A", label: "All" },
    { value: "C", label: "Completed only" },
    { value: "P", label: "Pending only" },
  ];

  financialPlanOptions = [
    { value: "A", label: "All" },
    { value: "1", label: "One Payment" },
    { value: "2", label: "Three Payments" },
    { value: "3", label: "One-Disc" },
    { value: "4", label: "Two-Disc" },
    { value: "5", label: "Three-Disc" },
  ];

  periodOptions = [
    { value: "A", label: "All" },
    { value: "M", label: "Morning" },
    { value: "E", label: "Evening" },
    { value: "MT", label: "Mon/Tue" },
    { value: "WT", label: "Wed/Thu" },
    { value: "HW", label: "Wed" },
  ];

  passedOptions = [
    { value: "A", label: "All" },
    { value: "1", label: "Yes" },
    { value: "0", label: "No" },
    { value: "2", label: "Other" },
  ];

  balanceOptions = [
    { value: "A", label: "All" },
    { value: "1", label: "Yes" },
    { value: "0", label: "No" },
  ];

  reasonOptions = [
    { value: "A", label: "All" },
    { value: "1", label: "Yes" },
    { value: "0", label: "No" },
  ];
}

export default SelfEnrollmentReport;
